import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { NotFound } from "navigation/NotFound";
import { BackTop } from "antd";
import {
  ROUTE_LOGIN,
  ROOT,
  ROUTE_HOME,
  ROUTE_VENDOR,
  ROUTE_SUPPLIER,
  ROUTE_TOPUP_SUPPLIER,
  ROUTE_TOPUP_VENDOR,
  ROUTE_USERS_LIST,
  ROUTE_WITHDRAW_VENDOR,
} from "navigation/CONSTANTS";
import PrivateRoute from "./Auth/PrivateRoute";
import LoginPage from "pages/LoginPage/LoginPage";

import { useDispatch } from "react-redux";
import { ChangeSize } from "redux/actions/appActions";
import MainLayout from "layouts/MainLayout/MainLayout";

import HomePage from "pages/HomePage/HomePage";
import VendorPage from "pages/VendorPage/VendorPage";
import SupplierPage from "pages/SupplierPage/SupplierPage";
import VendorTopupPage from "pages/VendorTopupPage/VendorTopupPage";
import SupplierTopupPage from "pages/SupplierTopupPage/SupplierTopupPage";
import UserPage from "pages/UserPage/UserPage";
import VendorWithdrawPage from "pages/VendorWithdrawPage/VendorWithdrawPage";
export const RouterConfig = () => {
  const screenSize = useWindowSize();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      ChangeSize({ width: screenSize.width, height: screenSize.height })
    );
  }, [screenSize]);

  return (
    <div>
      <Switch>
        <Route exact path={ROUTE_LOGIN} component={LoginPage} />

        {/* <SidebarLayout>
          <TopbarLayout> */}
        <MainLayout>
          <Switch>
            <PrivateRoute exact path={ROOT} component={HomePage} />
            <PrivateRoute exact path={ROUTE_HOME} component={HomePage} />
            <PrivateRoute exact path={ROUTE_VENDOR} component={VendorPage} />
            <PrivateRoute
              exact
              path={ROUTE_SUPPLIER}
              component={SupplierPage}
            />
            <PrivateRoute
              exact
              path={ROUTE_TOPUP_SUPPLIER}
              component={SupplierTopupPage}
            />

            <PrivateRoute
              exact
              path={ROUTE_TOPUP_VENDOR}
              component={VendorTopupPage}
            />
            <PrivateRoute
              exact
              path={ROUTE_WITHDRAW_VENDOR}
              component={VendorWithdrawPage}
            />
            <PrivateRoute exact path={ROUTE_USERS_LIST} component={UserPage} />

            {/* List a generic 404-Not Found route here */}
            <Route path="*" component={NotFound} />
          </Switch>
          <BackTop />
        </MainLayout>
        {/* </TopbarLayout>
        </SidebarLayout> */}
      </Switch>
    </div>
  );
};

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
