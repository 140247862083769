import { SearchOutlined } from "@ant-design/icons";
import { Input, message, Row, Col, Table, Spin } from "antd";
import { showTopupAcceptDlg } from "components/TopupAcceptDlg/showTopupAcceptDlg";
import { showTopupRejectDlg } from "components/TopupRejectDlg/showTopupRejectDlg";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addRemoveBalance,
  UpdateSupplierTopupList,
  UpdateVendorTopupList,
} from "redux/actions/appActions";
import {
  apiAcceptSupplierTopUp,
  apiRejectSupplierTopUp,
} from "services/supplierTopupService";
import {
  apiAcceptVendorTopUp,
  apiRejectVendorTopUp,
} from "services/vendorTopupService";

import "./TopupTableView.css";

const TopupTableView = (props) => {
  const { dataList, isSupplier } = props;
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const [selKeys, setSelKeys] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  const columns = [
    { title: "No", dataIndex: "order", width: 50 },
    {
      title: "Name",
      width: 150,
      dataIndex: "name",
      sorter: (a, b) => a.name > b.name,
    },
    {
      title: "Logo",
      dataIndex: "logo",
      width: 100,
      render: (logo) => {
        if (logo == undefined || logo == "") {
          return <div />;
        }
        return <img style={{ width: "100%" }} src={logo} />;
      },
    },

    {
      title: "MainUser",
      width: 100,
      dataIndex: "MainUser",
      sorter: (a, b) => a.MainUser > b.MainUser,
    },

    {
      title: "Request Price",

      width: 100,
      sorter: (a, b) => a.price > b.price,
      render: (item, data) => {
        return (
          <div
            key={`balance-item-${data["Currency"]["iso"]}`}
            style={{
              color:
                data["price"] >= 0 ? "var(--blueColor)" : "var(--redColor)",
            }}
          >
            {data["Currency"]["simple"]}
            {data["price"]}
          </div>
        );
      },
    },

    {
      title: "Description",
      width: 200,
      dataIndex: "description",
      sorter: (a, b) => a.description > b.description,
    },

    {
      title: "Balance",
      width: 170,
      sorter: (a, b) => a.balance > b.balance,
      render: (item, data) => {
        const balanceList = data["Balance"].map((balance) => {
          return (
            <div
              key={`balance-item-${balance["Currency"]["iso"]}`}
              style={{
                color:
                  balance["amount"] >= 0
                    ? "var(--blueColor)"
                    : "var(--redColor)",
              }}
            >
              {balance["Currency"]["simple"]}
              {balance["amount"]}
            </div>
          );
        });
        return balanceList;
      },
    },
    {
      title: "Created",
      dataIndex: "created",
      width: 110,
      sorter: (a, b) => a.created > b.created,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: 120,
      fixed: "right",
      render: (info) => {
        return (
          <div>
            <div style={{ textAlign: "center" }}>
              <span
                className="custom-button topup-table-view-button"
                style={{ color: "var(--blueColor)" }}
                onClick={() => {
                  onClickAcceptTopup(info);
                }}
              >
                Accept
              </span>
            </div>
            <div style={{ textAlign: "center" }}>
              <span
                className="custom-button topup-table-view-button"
                style={{ color: "var(--redColor)" }}
                onClick={() => {
                  onClickRejectTopup(info);
                }}
              >
                Reject
              </span>
            </div>
          </div>
        );
      },
    },
  ];
  const onClickAcceptTopup = async (info) => {
    const acceptAmount = await showTopupAcceptDlg({ info: info });
    if (acceptAmount == null) return;

    if (isSupplier) {
      updateSupplierList(info.topup_id, info.id, acceptAmount);
    } else {
      updateVendorList(info.topup_id, info.id, acceptAmount);
    }
  };
  const updateSupplierList = (topup_id, supplier_id, add_amount) => {
    setIsBusy(true);
    const topup = dataList.filter((x) => x.id == topup_id)[0];
    apiAcceptSupplierTopUp(topup_id, add_amount)
      .then(() => {
        setIsBusy(false);
        let data = dataList;

        let updateData = data.filter((x) => x.Supplier.id == supplier_id);
        const updatedbalance = addRemoveBalance(
          topup["Supplier"]["Balance"],
          topup["Currency"],
          add_amount
        );
        for (var i = 0; i < updateData.length; i++) {
          updateData[i]["Supplier"]["Balance"] = updatedbalance;
        }
        updateData = data.filter((x) => x.id == topup_id);
        for (var i = 0; i < updateData.length; i++) {
          updateData[i]["is_pending"] = false;
        }
        dispatch(UpdateSupplierTopupList(data));
      })
      .catch((err) => {
        setIsBusy(false);
        message.error("Request has been deleted.");
      });
  };
  const updateVendorList = (topup_id, vendor_id, add_amount) => {
    setIsBusy(true);
    const topup = dataList.filter((x) => x.id == topup_id)[0];
    apiAcceptVendorTopUp(topup_id, add_amount)
      .then(() => {
        setIsBusy(false);
        let data = dataList.filter((x) => x.id != topup_id);
        let updateData = data.filter((x) => x.Vendor.id == vendor_id);
        const updatedbalance = addRemoveBalance(
          topup["Vendor"]["Balance"],
          topup["Currency"],
          add_amount
        );
        for (var i = 0; i < updateData.length; i++) {
          updateData[i]["Vendor"]["Balance"] = updatedbalance;
        }
        updateData = data.filter((x) => x.id == topup_id);
        for (var i = 0; i < updateData.length; i++) {
          updateData[i]["is_pending"] = false;
        }
        dispatch(UpdateVendorTopupList(data));
      })
      .catch((err) => {
        setIsBusy(false);
        message.error("Request has been deleted.");
      });
  };

  const onClickRejectTopup = async (info) => {
    const rejectInfo = await showTopupRejectDlg({ info: info });
    if (rejectInfo == null) return;

    const updatedList = dataList.filter((x) => x.id != info.topup_id);
    if (isSupplier) {
      apiRejectSupplierTopUp(info.topup_id, rejectInfo);
      dispatch(UpdateSupplierTopupList(updatedList));
    } else {
      apiRejectVendorTopUp(info.topup_id, rejectInfo);
      dispatch(UpdateVendorTopupList(updatedList));
    }
  };

  return (
    <div>
      <Spin spinning={isBusy}>
        <Row justify={"space-between"}>
          <Col xs={24} md={12}>
            <Input
              prefix={<SearchOutlined />}
              className="topup-data-search-input"
              placeholder={"Search by keyword"}
              type={"search"}
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Table
          className="topup-list-view-table"
          columns={columns}
          dataSource={dataList
            .filter((x) => {
              const Target = isSupplier ? x.Supplier : x.Vendor;
              return (
                (Target.name != undefined &&
                  Target.name.toLowerCase().includes(keyword.toLowerCase())) ||
                (Target.MainUser != undefined &&
                  Target.MainUser.toLowerCase().includes(
                    keyword.toLowerCase()
                  )) ||
                (Target.Subscription != undefined &&
                  Target.Subscription.toLowerCase().includes(
                    keyword.toLowerCase()
                  )) ||
                (Target.email != undefined &&
                  Target.email.toLowerCase().includes(keyword.toLowerCase()))
              );
            })
            .map((x, index) => {
              const Target = isSupplier ? x.Supplier : x.Vendor;

              return {
                key: x.id,
                ...Target,
                order: index + 1,
                price: x.price,
                Currency: x.Currency,
                description: x.description,
                created: x.created.substring(0, 10),
                topup_id: x.id,
              };
            })}
          scroll={{ x: 1000 }}
        />
      </Spin>
    </div>
  );
};

export default TopupTableView;
