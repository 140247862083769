import React from "react";
import "./MainLayout.css";
import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { ROUTE_LOGIN } from "navigation/CONSTANTS";
import { showMyAlertDialog } from "components/MyAlertDialog/showMyAlertDialog";
import SidebarLayout from "layouts/SidebarLayout/SidebarLayout";
import TopbarLayout from "layouts/TopbarLayout/TopbarLayout";
import { setFromUrl } from "redux/actions/userActions";
import { useDispatch } from "react-redux";
const MainLayout = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleOnIdle = async (event) => {
    dispatch(setFromUrl(history.location));
    await showMyAlertDialog({
      title: "Security Alert",
      content: (
        <div>
          <p>Sorry...</p>
          <p>You have been an idle status for 15 minutes.</p>
          <p>Please login again.</p>
        </div>
      ),
      subTitle: "",
    });

    history.replace(ROUTE_LOGIN);

  };

  const handleOnActive = (event) => {

  };

  const handleOnAction = (event) => {

  };
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  return (
    <SidebarLayout>
      <TopbarLayout>
        <div className="main-page">{props.children}</div>
      </TopbarLayout>
    </SidebarLayout>
  );
};

export default MainLayout;
