import React from "react";
import "./UsersListWidget.css";
import { Table } from "antd";
const UsersListWidget = (props) => {
  const { totalCount, usersList, isBusy, offset, pageSize, readUsersList } =
    props;

  const columns = [
    { title: "No", dataIndex: "id", width: 50 },
    {
      title: "Avatar",
      dataIndex: "image_url",
      width: 60,
      render: (image_url) => {
        if (image_url == undefined || image_url == "") {
          return <div />;
        }
        return (
          <img
            style={{ width: 50, height: 50, borderRadius: "50%" }}
            src={image_url}
          />
        );
      },
    },
    {
      title: "First Name",
      width: 150,
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name > b.first_name,
    },
    {
      title: "Last Name",
      width: 150,
      dataIndex: "last_name",
      sorter: (a, b) => a.last_name > b.last_name,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address > b.address,
    },
    {
      title: "Created",
      dataIndex: "created",
      sorter: (a, b) => a.created > b.created,
      render: (created) => {
        return <div>{created.replace("T", "").substring(0, 10)}</div>;
      },
    },
  ];
  const onClickPagination = (pagination, filters, sorter) => {
    const { current } = pagination;

    readUsersList(pageSize * (current - 1), pageSize);
  };
  return (
    <div className="user-search-detail-widget">
      Search Result: {totalCount} users
      <div>
        <Table
          className="topup-list-view-table"
          columns={columns}
          dataSource={usersList.map((x) => ({ ...x, key: x.id }))}
          loading={isBusy}
          pagination={{
            current: Math.floor(offset / pageSize) + 1,
            pageSize: pageSize,
            total: totalCount,
          }}
          onChange={onClickPagination}
        />
      </div>
    </div>
  );
};

export default UsersListWidget;
