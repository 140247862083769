import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Layout } from "antd";
import "./SidebarLayout.css";
import SidebarWidget from "components/SidebarWidget/SidebarWidget";
import { ChangeMenuCollapsed } from "redux/actions/appActions";

const { Sider } = Layout;
function SidebarLayout(props) {
  const dispatch = useDispatch();
  const appDataStore = useSelector((x) => x.appDataStore);
  const { collapsed, width } = appDataStore;

  return (
    <Layout>
      {width > 1024 ? (
        <Sider collapsed={collapsed} className="custom-sidebar">
          <SidebarWidget />
        </Sider>
      ) : (
        <Drawer
          placement={"left"}
          visible={!collapsed}
          className="custom-drawer"
          onClose={() => {
            dispatch(ChangeMenuCollapsed({ collapsed: true }));
          }}
        >
          <SidebarWidget />
        </Drawer>
      )}

      <Layout>{props.children}</Layout>
    </Layout>
  );
}

export default SidebarLayout;
