import { Input, message, Modal } from "antd";
import React, { useState } from "react";
import { confirmable } from "react-confirm";
import "./EmailInfoDlg.css";
const { TextArea } = Input;
const EmailInfoDlg = (props) => {
  const { show, proceed } = props;
  const [emailDetail, setEmailDetail] = useState({
    message: "",
    email_subject: "",
    rel_url: "",
  });

  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      className="radial-modal"
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={true}
      title={"Email Detail"}
      onOk={() => {
        if (emailDetail.message == "") {
          message.error("Content is empty.");
          return;
        }
        proceed(emailDetail);
      }}
    >
      <div>Subject(optional)</div>
      <Input
        placeholder={"Input Email Subject (From SeeMeSave)"}
        onChange={(e) =>
          setEmailDetail({ ...emailDetail, email_subject: e.target.value })
        }
      />
      <div style={{ marginTop: 20 }}>Content(*)</div>
      <TextArea
        rows={5}
        maxLength={500}
        showCount
        placeholder={"Input message you want to send."}
        onChange={(e) =>
          setEmailDetail({ ...emailDetail, message: e.target.value })
        }
      />
      <div style={{ marginTop: 20 }}>Related Link(optional)</div>
      <Input
        placeholder={"Input SeeMeSave Link. (https://seemesave.com)"}
        onChange={(e) =>
          setEmailDetail({ ...emailDetail, rel_url: e.target.value })
        }
      />
    </Modal>
  );
};

export default confirmable(EmailInfoDlg);
