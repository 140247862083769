import { axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlVendorEmailSend, urlVendorPurchase, urlVendorWithdraw } from "./CONSTANTS";

export const apiSendVendorEmail = (param) => {
  const url = urlVendorEmailSend;
  return new Promise((resolve, reject) => {
    axiosPost(url, param, "getVendorlist")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiPurchaseVendor = (param) => {
  const url = urlVendorPurchase;
  return new Promise((resolve, reject) => {
    axiosPost(url, param, "purchaseVendor ")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiWithdrawVendor = (param) => {
  const url = urlVendorWithdraw;
  return new Promise((resolve, reject) => {
    axiosPut(url, param, "apiWithdrawVendor ")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
