import { axiosGet } from "./ajaxServices";
import {
  urlGetAddressList,
  urlGetCoordFromPlaceId,
  urlGetUsersList,
  urlGoogleAutoComplete,
} from "./CONSTANTS";

export const apiGetAddressList = () => {
  const url = urlGetAddressList;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, "apiGetAddressList")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAddresses = (
  keyword,
  countries = ["ZA"],
  search_type = "address"
) => {
  const url = urlGoogleAutoComplete;
  let str_countries = [];
  for (var i = 0; i < countries.length; i++) {
    str_countries.push(`country:${countries[i]}`);
  }
  str_countries = str_countries.join("|");

  const param = {
    keyword: keyword,
    str_countries: str_countries,
    search_type: search_type,
  };

  return new Promise((resolve, reject) => {
    axiosGet(url, param, "getAddresses")
      .then((res) => {
        resolve(res.predictions);
      })
      .catch((err) => {
        reject([]);
      });
  });
};

export const apiGetUsersList = (
  offset = 0,
  page_size = 10,
  user_name = "",
  place_id = "",
  from_date = ""
) => {
  const url = urlGetUsersList;

  return new Promise((resolve, reject) => {
    axiosGet(
      url,
      {
        offset: offset,
        page_size: page_size,
        user_name: user_name,
        place_id: place_id,
        from_date: from_date,
      },
      "apiGetUsersList"
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
