import React, { useState, useEffect } from "react";
import "./InputLocationWidget.css";
import AsyncSelect from "react-select/async";
import { getAddresses, getCoordinateFromPlaceID } from "services/usersServices";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
};
const InputLocationWidget = (props) => {
  const { onSelectLocation } = props;

  const promiseOptions = (keyword) =>
    new Promise((resolve, reject) => {
      if (keyword === "" || keyword === undefined) {
        reject();
      } else {
        getAddresses(keyword, ["ZA"], "(regions)")
          .then((res) => {
            resolve(
              res.map((info) => ({
                value: info.place_id,
                label: info.description,
              }))
            );
          })
          .catch((err) => reject());
      }
    });
  return (
    <div className="input-location-widget">
      <AsyncSelect
        cacheOptions
        styles={customStyles}
        defaultOptions
        loadOptions={promiseOptions}
        placeholder={"Write the city or town..."}
        onChange={(info) => {
          onSelectLocation(info);
        }}

      />
    </div>
  );
};

export default InputLocationWidget;
