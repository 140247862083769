// @flow strict

import { Modal } from "antd";
import * as React from "react";
import { confirmable } from "react-confirm";
import "./MyAlertDialog.css"

function MyAlertDialog(props) {
  const { show, proceed } = props;
  const { title, content, subTitle, action } = props;
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      className="alert-modal"
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={true}
      title={title}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => {
        proceed(null);
      }}
    >
      {subTitle}
      <div className="alert-content">{content}</div>
    </Modal>
  );
}

export default confirmable(MyAlertDialog);
