import React from 'react';
import './InfoPaneWidget.css'

const InfoPaneWidget = (props) => {
    return (
        <div className="info-pane-widget">
            {props.children}
        </div>
    );
};

export default InfoPaneWidget;