import { SearchOutlined } from "@ant-design/icons";
import { Input, message, Row, Col, Table, Spin } from "antd";
import { showEmailInfoDlg } from "components/EmailInfoDlg/showEmailInfoDlg";
import { showPurchaseInfoDlg } from "components/PurchaseInfoDlg/showPurchaseInfoDlg";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addRemoveBalance,
  UpdateSupplierList,
  UpdateVendorList,
} from "redux/actions/appActions";
import {
  apiPurchaseSupplier,
  apiSendSupplierEmail,
  apiWithdrawSupplier,
} from "services/supplierService";
import {
  apiPurchaseVendor,
  apiSendVendorEmail,
  apiWithdrawVendor,
} from "services/vendorService";

import "./TableViewer.css";

const TableViewer = (props) => {
  const { dataList, isSupplier } = props;
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const [selKeys, setSelKeys] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const columns = [
    { title: "No", dataIndex: "order", width: 50 },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name > b.name,
    },
    {
      title: "Logo",
      dataIndex: "logo",
      width: 100,
      render: (logo) => {
        if (logo == undefined || logo == "") {
          return <div />;
        }
        return <img style={{ width: "100%" }} src={logo} />;
      },
    },

    {
      title: "MainUser",
      dataIndex: "MainUser",
      sorter: (a, b) => a.MainUser > b.MainUser,
    },
    {
      title: "Agent ",
      dataIndex: "AgentCount",
      width: 60,
      sorter: (a, b) => a.AgentCount > b.AgentCount,
    },
    {
      title: "Follower",
      dataIndex: "FollowerCount",
      width: 70,
      sorter: (a, b) => a.FollowerCount > b.FollowerCount,
    },
    {
      title: "Balance",
      width: 170,
      render: (item, data) => {
        const balanceList = data["Balance"].map((balance) => {
          return (
            <div
              key={`balance-item-${balance["Currency"]["iso"]}`}
              style={{
                color:
                  balance["amount"] >= 0
                    ? "var(--blueColor)"
                    : "var(--redColor)",
              }}
            >
              {balance["Currency"]["simple"]}
              {balance["amount"]}
            </div>
          );
        });
        return balanceList;
      },
    },

    // {
    //   title: "Purchase Date",
    //   dataIndex: "PurchaseDate",
    //   width: 110,
    //   sorter: (a, b) => a.PurchaseDate > b.PurchaseDate,
    // },
    {
      title: "Created",
      dataIndex: "created",
      width: 110,
      sorter: (a, b) => a.created > b.created,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 60,
      sorter: (a, b) => a.status > b.status,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: 120,
      fixed: "right",
      render: (info) => {
        return (
          <div>
            <div>
              <span
                className="custom-button table-view-button"
                onClick={() => onClickContact(info)}
              >
                Contact
              </span>
            </div>
            <div>
              <span
                className="custom-button table-view-button"
                style={{ color: "var(--redColor)" }}
                onClick={() => onClickPurchase(info)}
              >
                Purchase
              </span>
            </div>
            <div>
              <span
                className="custom-button table-view-button"
                style={{ color: "var(--greenColor)" }}
                onClick={() => onClickWithdraw(info)}
              >
                Withdraw
              </span>
            </div>
          </div>
        );
      },
    },
  ];
  const onClickPurchase = async (info) => {
    const orgValue = dataList.find((x) => x.id == info.id);
    const index = dataList.indexOf(orgValue);

    if (index == -1) {
      message.error("Something went wrong. Please refresh site.");
      return;
    }

    if (info.MainUser == "") {
      message.error("It's not connected to user.");
      return;
    }

    const dlgRes = await showPurchaseInfoDlg({
      ...info,
      title: "Purchase Information",
      isPurchase: true,
    });

    if (dlgRes == null) return;
    const currency = orgValue.Balance.find(
      (x) => x["Currency"]["iso"] == dlgRes["currency"]
    )["Currency"];
    const tmpBalance = addRemoveBalance(
      orgValue.Balance,
      currency,
      dlgRes["amount"]
    );
    const tmpList = [...dataList];
    tmpList[index] = { ...orgValue, Balance: tmpBalance };
    setIsBusy(true);
    if (isSupplier) {
      apiPurchaseSupplier({
        supplier_id: info.id,
        price: dlgRes["amount"],
        currency: dlgRes["currency"],
      })
        .then((res) => {
          message.success("Successfully purchased.");
          setIsBusy(false);
          dispatch(UpdateSupplierList([...tmpList]));
        })
        .catch((err) => {
          setIsBusy(false);
          message.error(err);
        });
    } else {
      apiPurchaseVendor({
        vendor_id: info.id,
        price: dlgRes["amount"],
        currency: dlgRes["currency"],
      })
        .then((res) => {
          setIsBusy(false);
          message.success("Successfully purchased.");
          dispatch(UpdateVendorList([...tmpList]));
        })
        .catch((err) => {
          setIsBusy(false);
          message.error(err);
        });
    }
  };
  const onClickWithdraw = async (info) => {
    const orgValue = dataList.find((x) => x.id == info.id);
    const index = dataList.indexOf(orgValue);

    if (index == -1) {
      message.error("Something went wrong. Please refresh site.");
      return;
    }

    if (info.MainUser == "") {
      message.error("It's not connected to user.");
      return;
    }
    const dlgRes = await showPurchaseInfoDlg({
      ...info,
      title: "Withdraw Information",
      isPurchase: false,
    });
    if (dlgRes == null) return;
    const currency = orgValue.Balance.find(
      (x) => x["Currency"]["iso"] == dlgRes["currency"]
    )["Currency"];
    const tmpBalance = addRemoveBalance(
      orgValue.Balance,
      currency,
      -dlgRes["amount"]
    );
    const tmpList = [...dataList];
    tmpList[index] = { ...orgValue, Balance: tmpBalance };
    setIsBusy(true);
    if (isSupplier) {
      apiWithdrawSupplier({
        supplier_id: info.id,
        price: dlgRes["amount"],
        currency: dlgRes["currency"],
      })
        .then((res) => {
          message.success("Successfully withdrawed.");
          setIsBusy(false);
          dispatch(UpdateSupplierList([...tmpList]));
        })
        .catch((err) => {
          setIsBusy(false);
          message.error(err);
        });
    } else {
      apiWithdrawVendor({
        vendor_id: info.id,
        price: dlgRes["amount"],
        currency: dlgRes["currency"],
      })
        .then((res) => {
          setIsBusy(false);
          message.success("Successfully withdrawed.");
          dispatch(UpdateVendorList([...tmpList]));
        })
        .catch((err) => {
          setIsBusy(false);
          message.error(err);
        });
    }
  };
  const onClickContact = async (info) => {
    SendEmail(`${info.id}`);
  };
  const onClickSendEmailSelected = (info) => {
    if (selKeys.length == 0) return;
    const str_list = selKeys.join(",");
    SendEmail(str_list);
  };
  const onClickSendEmailAll = () => {
    SendEmail("");
  };
  const SendEmail = async (str_dest) => {
    const emailInfo = await showEmailInfoDlg();
    if (emailInfo == null) return;
    if (isSupplier) {
      apiSendSupplierEmail({
        ...emailInfo,
        supplier_list: str_dest,
      }).then((res) => {
        message.success("Email has been sent successfully.");
      });
    } else {
      apiSendVendorEmail({
        ...emailInfo,
        vendor_list: str_dest,
      }).then((res) => {
        message.success("Email has been sent successfully.");
      });
    }
  };
  const onSelectChange = (selectedRowKeys) => {
    setSelKeys(selectedRowKeys);
  };

  const rowSelection = {
    selKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Spin spinning={isBusy}>
        <Row justify={"space-between"}>
          <Col xs={24} md={12}>
            <Input
              prefix={<SearchOutlined />}
              className="data-search-input"
              placeholder={"Search by keyword"}
              type={"search"}
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
          </Col>
          <Col xs={24} md={12} style={{ textAlign: "right" }}>
            <span
              className={`${
                selKeys.length > 0
                  ? "send-email-button custom-button"
                  : "send-email-button send-email-button-disable"
              }`}
              onClick={onClickSendEmailSelected}
            >
              Send Email
            </span>
            <span
              className={"send-email-button custom-button"}
              style={{ marginLeft: 30 }}
              onClick={onClickSendEmailAll}
            >
              Send Email To All
            </span>
          </Col>
        </Row>
        <Table
          className="list-view-table"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataList
            .filter(
              (x) =>
                (x.name != undefined &&
                  x.name.toLowerCase().includes(keyword.toLowerCase())) ||
                (x.MainUser != undefined &&
                  x.MainUser.toLowerCase().includes(keyword.toLowerCase())) ||
                (x.Subscription != undefined &&
                  x.Subscription.toLowerCase().includes(
                    keyword.toLowerCase()
                  )) ||
                (x.email != undefined &&
                  x.email.toLowerCase().includes(keyword.toLowerCase()))
            )
            .map((x, index) => ({
              ...x,
              key: x.id,
              order: index + 1,
              created: x.created.substring(0, 10),
              status: x.is_closed ? "Closed" : "",
            }))}
          scroll={{ x: 1200 }}
        />
      </Spin>
    </div>
  );
};

export default TableViewer;
