import { axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlSupplierEmailSend, urlSupplierPurchase, urlSupplierWithdraw } from "./CONSTANTS";

export const apiSendSupplierEmail = (param) => {
  const url = urlSupplierEmailSend;
  return new Promise((resolve, reject) => {
    axiosPost(url, param, "sendSupplierEmail")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiPurchaseSupplier = (param) => {
  const url = urlSupplierPurchase;
  return new Promise((resolve, reject) => {
    axiosPost(url, param, "purchaseSupplier ")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiWithdrawSupplier = (param) => {
  const url = urlSupplierWithdraw;
  return new Promise((resolve, reject) => {
    axiosPut(url, param, "apiWithdrawSupplier ")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
