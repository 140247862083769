import { Spin } from "antd";
import TableViewer from "components/TableViewer/TableViewer";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateVendorList } from "redux/actions/appActions";
import { getVendorList } from "services/homeService";
import "./VendorPage.css";
const VendorPage = () => {
  const appDataStore = useSelector((x) => x.appDataStore);
  const dispatch = useDispatch();
  useEffect(() => {
    getVendorList().then((res) => {
      dispatch(UpdateVendorList(res.data));
    });
  }, []);
  const { vendorList } = appDataStore;
  if (vendorList === undefined) {
    return (
      <Spin>
        <div style={{ height: 300, textAlign: "center", paddingTop: 150 }}>
          Loading Data
        </div>
      </Spin>
    );
  }
  return (
    <div>
      <TableViewer dataList={vendorList} isSupplier={false} />
    </div>
  );
};

export default VendorPage;
