import { axiosDelete, axiosGet, axiosPost } from "./ajaxServices";
import { urlAcceptVendorTopup, urlGetVendorTopup } from "./CONSTANTS";

export const apiGetVendorTopUps = () => {
  const url = urlGetVendorTopup;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, "apiGetVendorTopUps ")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiAcceptVendorTopUp = (topup_id, updated_price = 0) => {
  const url = urlAcceptVendorTopup;
  let param = {
    topup_id: topup_id,
    updated_price: updated_price,
  };

  return new Promise((resolve, reject) => {
    axiosPost(url, param, "apiAcceptVendorTopUp ")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiRejectVendorTopUp = (topup_id, description = "") => {
  const url = urlAcceptVendorTopup;
  let param = {
    topup_id: topup_id,
    description: description,
  };
  

  return new Promise((resolve, reject) => {
    axiosDelete(url, param, "apiRejectVendorTopUp ")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
