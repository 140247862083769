import { axiosGet } from "./ajaxServices";
import { urlGetVendorList, urlGetSupplierList } from "./CONSTANTS";

export const getVendorList = () => {
  const url = urlGetVendorList;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, "getVendorlist")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSupplierList = () => {
  const url = urlGetSupplierList;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, "getSupplierlist")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
