import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row, Select } from "antd";
import NormalButton from "components/ButtonWidgets/NormalButton/NormalButton";
import React from "react";
import { useState } from "react";
import { DATE_LIST } from "services/commonService";
import InputLocationWidget from "../InputLocationWidget/InputLocationWidget";
import "./UserSearchDetailWidget.css";
const { Option } = Select;
const UserSearchDetailWidget = (props) => {
  const { readUsersList, prevFromDate } = props;
  const [username, setUsername] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [fromDate, setFromDate] = useState(DATE_LIST[0]);
  const onClickSearch = (_placeId, _fromDate) => {
    if (_placeId == undefined) {
      _placeId = placeId;
    } else {
      setPlaceId(_placeId);
    }
    if (_fromDate == undefined) {
      _fromDate = fromDate;
    } else {
      setFromDate(_fromDate);
    }
    readUsersList(0, 10, username, _placeId, _fromDate);
  };
  const onSelectLocation = (info) => {
    const { label, value } = info;
    onClickSearch(value, fromDate);
    // setPlaceId(value);
  };
  const onSelectFromDate = (info) => {
    onClickSearch(placeId, info);
  };
  return (
    <div className="user-search-detail-widget">
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <div>Search by username</div>
          <div>
            <Input
              prefix={<SearchOutlined />}
              className="topup-data-search-input"
              placeholder={"Type keyword for username"}
              type={"search"}
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              onPressEnter={() => {
                onClickSearch();
              }}
            />
          </div>
          <div>Search by region</div>
          <InputLocationWidget onSelectLocation={onSelectLocation} />
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <span
              className={"custom-button search-user-button"}
              onClick={() => onClickSearch()}
            >
              <SearchOutlined /> Search
            </span>
          </div>
        </Col>
        <Col xs={24} md={12}>
          <Row justify={"center"}>
            <Col>
              {DATE_LIST.map((dateInfo, index) => {
                return (
                  <div key={`date-select-key-${index}`}>
                    <span
                      className="custom-button"
                      style={{
                        color:
                          prevFromDate.title == dateInfo.title
                            ? "black"
                            : "gray",
                      }}
                      onClick={() => {
                        onSelectFromDate(dateInfo);
                      }}
                    >
                      {dateInfo.title}
                    </span>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default UserSearchDetailWidget;
