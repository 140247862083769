import { SET_FROM_URL, UPDATE_SUBSCRIPTION, UPDATE_USER } from "redux/actions/userActions";

const initialState = {};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case SET_FROM_URL:
      return {
        ...state,
        fromUrl: action.payload,
      };
    default:
      return state;
  }
};
