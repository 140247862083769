import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Col, Dropdown, Row, Space } from "antd";

import { MENU_ITEMS } from "navigation/CONSTANTS";
import { ROUTE_LOGIN } from "navigation/CONSTANTS";

import "./TopbarLayout.css";
import { IconWidget } from "components/IconWidget/IconWidget";
import collapseIcon from "../../assets/icons/ic_collapse.svg";
import { ChangeMenuCollapsed } from "redux/actions/appActions";
function TopbarLayout(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const appDataStore = useSelector((x) => x.appDataStore);
  const { collapsed, width, currentRoute } = appDataStore;
  const userDataStore = useSelector((x) => x.userDataStore);
  const [currentPathTitle, setCurrentPathTitle] = useState("Overview");
  useEffect(() => {
    const same = MENU_ITEMS.filter(
      (item) => item.route == history.location.pathname
    );
    let name = same.length === 0 ? "Overview" : same[0].title;
    setCurrentPathTitle(name);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, [history.location.pathname]);
  const userInfo = userDataStore.user;

  if (userInfo === undefined) {
    return <div>{props.children}</div>;
  }

  if (width <= 1024) {
    return (
      <div>
        <div className="logo">
          <img src={"/assets/images/logo.svg"} alt="SeeMeSave" />
          <div
            className="side-collapse"
            onClick={() => {
              dispatch(ChangeMenuCollapsed({ collapsed: !collapsed }));
            }}
          >
            <IconWidget Icon={collapseIcon} />
          </div>
        </div>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Row
            className="top-setting-bg"
            justify={"space-between"}
            align={"middle"}
          >
            <Col>
              <span className="txt-top-welcome">Welcome Back!</span>{" "}
              <span className="txt-top-name">{userInfo.first_name}</span>
            </Col>
            <Col className="gutter-row">
              <div>
                <img
                  style={{ cursor: "pointer" }}
                  src="/assets/icons/ic_logout.svg"
                  alt=""
                />
                <span
                  className="txt-top-logout"
                  onClick={() => {
                    history.push(ROUTE_LOGIN);
                  }}
                >
                  Log out
                </span>
              </div>
            </Col>
          </Row>
        </div>

        <span className="txt-top-title">{currentPathTitle}</span>

        <div>{props.children}</div>
      </div>
    );
  }

  return (
    <div>
      <div className="top-navbar-layout">
        <div className="top-navbar-header">
          <Row align={"middle"} gutter={16} justify={"space-between"}>
            <Col>
              <span className="txt-top-title">{currentPathTitle}</span>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <Row
                align={"middle"}
                className="top-setting-bg"
                justify={"space-between"}
              >
                <Col align={"middle"}>
                  <span className="txt-top-welcome">Welcome Back!</span>{" "}
                  <span className="txt-top-name">{userInfo.first_name}</span>
                </Col>
                <Col>
                  <Row align={"middle"}>
                    <Col className="gutter-row"></Col>
                    <Col className="gutter-row">
                      <div>
                        <img
                          className="user-profile-image"
                          src={
                            userInfo.image_url == null ||
                            userInfo.image_url == ""
                              ? "/assets/icons/ic_logout.svg"
                              : userInfo.image_url
                          }
                          alt=""
                        />
                        <span
                          className="txt-top-logout"
                          onClick={() => {
                            history.push(ROUTE_LOGIN);
                          }}
                        >
                          Log out
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>{props.children}</div>
    </div>
  );
}

export default TopbarLayout;
