import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Input, message } from "antd";

import "./TopupAcceptDlg.css";
import { confirmable } from "react-confirm";
const TopupAcceptDlg = (props) => {
  const { show, proceed } = props;
  const { info } = props;
  const { name, MainUser, Balance, price, Currency } = info;
  const [amount, setAmount] = useState(price);
  const selBalance = Balance.find(
    (x) => x["Currency"]["iso"] == Currency["iso"]
  );
  const currentBalance =
    selBalance == undefined
      ? amount
      : parseFloat(selBalance["amount"]) + parseFloat(amount);
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      className="radial-modal"
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={true}
      title={"TopUp Request Accept"}
      onOk={() => {
        try {
          if (parseFloat(amount) <= 0) {
            message.error("Amount should be greater than R0.");
            return;
          }
          if (currentBalance < 0) {
            message.error("Specified amount is invalid.");
            return;
          }
        } catch (e) {
          message.error("Amount is not valid.");
          return;
        }
        proceed(parseInt(amount));
      }}
    >
      <div>Vendor: {name}</div>
      <div>User Name: {MainUser}</div>
      <div
        style={{ marginTop: 10, color: "var(--redColor)", marginBottom: 10 }}
      >
        * If you received payment is not same, you can correct the amount.
      </div>
      <Row align={"middle"} gutter={16}>
        <Col>
          <div>Amount</div>
        </Col>
        <Col>
          <Input
            className={"purchase-amount-input"}
            prefix={Currency["simple"]}
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
        </Col>
      </Row>
      <div style={{ textAlign: "center", marginTop: 10 }}>
        The balance will be{" "}
        <span
          style={{
            fontFamily: "mediumFont",
            color:
              currentBalance >= 0 ? "var(--blackColor)" : "var(--redColor)",
          }}
        >
          {`${currentBalance >= 0 ? "" : "-"}` +
            Currency["simple"] +
            Math.abs(currentBalance)}
        </span>
      </div>
    </Modal>
  );
};

export default confirmable(TopupAcceptDlg);
