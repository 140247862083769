// All user related database operations can be defined here.
import axios from "axios";
import { REGISTER_WITH, SYSTEM_ERROR } from "config/CONSTANTS";
import { axiosGet, axiosPut } from "./ajaxServices";
import { urlUserLogin } from "./CONSTANTS";

export const apiLogin = (userDetail) => {
  const { register_with, email, phone, password } = userDetail;
  const url = urlUserLogin;
  let param = { password: password, register_with: register_with };

  if (register_with === REGISTER_WITH.email) {
    param["email"] = email;
  } else {
    param["phone"] = phone;
  }

  return new Promise((resolve, reject) => {
    try {
      axios
        .post(url, param)
        .then((res) => {
          const { data } = res;
          if (data.status === true) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((err) => {
          reject("Error in getUserDetails axios!");
        });
    } catch (error) {
      console.error("in userServices > apiLogin, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
