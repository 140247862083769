import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Input, message, Spin, Divider } from "antd";

import { confirmable } from "react-confirm";
import "./WithdrawAcceptDlg.css";
import {
  apiAcceptVendorWithdraw,
  apiGetVendorBankDetail,
} from "services/vendorWithdrawService";
import { PrinterOutlined } from "@ant-design/icons";
import { BASE_URL } from "services/CONSTANTS";
const WithdrawAcceptDlg = (props) => {
  const { show, proceed } = props;
  const { info } = props;
  const { price, name, MainUser, Balance, withdraw_id, Currency } = info;

  const [isBusy, setIsBusy] = useState(false);

  const [bankDetail, setBankDetail] = useState();

  const [amount, setAmount] = useState(price);
  const selBalance = Balance.find(
    (x) => x["Currency"]["iso"] == Currency["iso"]
  );
  const currentBalance =
    selBalance == undefined
      ? amount
      : parseFloat(selBalance["amount"]) - parseFloat(amount);

  useEffect(() => {
    apiGetVendorBankDetail(withdraw_id)
      .then((res) => {
        setBankDetail(res);
      })
      .catch((err) => {
        message.error("Bank Detail is incorrect.");
        proceed(null);
      });
  }, []);
  const onClickAccept = () => {
    setIsBusy(true);
    apiAcceptVendorWithdraw(withdraw_id)
      .then((res) => {
        message.success("Success");
        setIsBusy(false);
        proceed(true);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onClickPrint = async () => {
    window
      .open(
        BASE_URL + "/preview/withdraw/vendor/?withdraw_id=" + withdraw_id,
        "_blank"
      )
      .focus();
  };
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      className="radial-modal"
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={true}
      title={"Withdraw Request Accept"}
      onOk={() => {
        onClickAccept();
      }}
    >
      <Spin spinning={isBusy}>
        <Row justify={"space-between"} align={"middle"}>
          <Col>
            <div>Vendor: {name}</div>
            <div>User Name: {MainUser}</div>
          </Col>
          <Col>
            <span
              className="custom-button withdraw-print-button"
              onClick={onClickPrint}
            >
              <PrinterOutlined />
              {"  "}
              Print
            </span>
          </Col>
        </Row>

        <Divider style={{ fontSize: 14 }} orientation={"left"}>
          Bank Detail
        </Divider>
        <Spin spinning={bankDetail == undefined}>
          <div style={{ minHeight: 50 }}>
            {bankDetail !== undefined && (
              <div>
                <Row align={"middle"} gutter={16}>
                  <Col>
                    <div>Account Name:</div>
                  </Col>
                  <Col>{bankDetail.account_name}</Col>
                </Row>
                <Row align={"middle"} gutter={16}>
                  <Col>
                    <div>Account Number:</div>
                  </Col>
                  <Col>{bankDetail.account_number}</Col>
                </Row>
                <Row align={"middle"} gutter={16}>
                  <Col>
                    <div>Branch Code:</div>
                  </Col>
                  <Col>{bankDetail.branch_code}</Col>
                </Row>
                <Row align={"middle"} gutter={16}>
                  <Col>
                    <div>Branch Name:</div>
                  </Col>
                  <Col>{bankDetail.branch_name}</Col>
                </Row>
              </div>
            )}
          </div>
        </Spin>
        <Divider style={{ fontSize: 14 }} orientation={"left"}>
          Request Detail
        </Divider>
        {/* <Row align={"middle"} gutter={16}>
          <Col>
            <div>Requested Amount:</div>
          </Col>
          <Col>R{price}</Col>
        </Row>
        <Row align={"middle"} gutter={16}>
          <Col>
            <div>Transaction Fee:</div>
          </Col>
          <Col>R{(price * 0.03).toFixed(2)}</Col>
        </Row> */}
        <Row align={"middle"} gutter={16} style={{ marginBottom: 20 }}>
          <Col>
            <div>Withdrawal Amount:</div>
          </Col>
          <Col style={{ fontSize: 20, fontWeight: "bold" }}>
            {Currency["simple"]}
            {parseFloat(price).toFixed(2)}
          </Col>
        </Row>

        <div style={{ textAlign: "center", marginTop: 10 }}>
          The balance will be{" "}
          <span
            style={{
              fontFamily: "mediumFont",
              color:
                currentBalance >= 0 ? "var(--blackColor)" : "var(--redColor)",
            }}
          >
            {`${currentBalance >= 0 ? "" : "-"}` +
              Currency["simple"] +
              Math.abs(currentBalance)}
          </span>
        </div>
      </Spin>
    </Modal>
  );
};

export default confirmable(WithdrawAcceptDlg);
