import { Spin } from "antd";
import WithdrawTableView from "components/WithdrawTableView/WithdrawTableView";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { UpdateVendorWithdrawList } from "redux/actions/appActions";
import { apiGetVendorWithdrawList } from "services/vendorWithdrawService";
import "./VendorWithdrawPage.css";
const VendorWithdrawPage = (props) => {
  const dispatch = useDispatch();
  const appDataStore = useSelector((x) => x.appDataStore);
  useEffect(() => {
    apiGetVendorWithdrawList()
      .then((res) => {
        dispatch(UpdateVendorWithdrawList(res.data));
      })
      .catch((err) => {});
  }, []);
  const { vendorWithdrawList } = appDataStore;
  if (vendorWithdrawList === undefined) {
    return (
      <Spin>
        <div style={{ height: 300, textAlign: "center", paddingTop: 150 }}>
          Loading Data
        </div>
      </Spin>
    );
  }
  return (
    <div>
      <WithdrawTableView dataList={vendorWithdrawList} isSupplier={false} />
    </div>
  );
};

export default VendorWithdrawPage;
