// You can use CONSTANTS.js file for below definitions of constants and import here.
export const SIZE_CHANGED = "SIZE_CHANGED";
export const MENU_COLLAPSED_CHANGED = "MENU_COLLAPSED_CHANGED";
export const CURRENT_ROUTE = "CURRENT_ROOT";
export const UPDATE_VENDOR_LIST = "UPDATE_VENDOR_LIST";
export const UPDATE_SUPPLIER_LIST = "UPDATE_SUPPLIER_LIST";
export const UPDATE_VENDOR_TOPUP_LIST = "UPDATE_VENDOR_TOPUP_LIST";
export const UPDATE_SUPPLIER_TOPUP_LIST = "UPDATE_SUPPLIER_TOPUP_LIST";
export const UPDATE_VENDOR_WITHDRAW_LIST = "UPDATE_VENDOR_WITHDRAW_LIST";

export const ChangeSize = (payload) => ({
  type: SIZE_CHANGED,
  payload,
});

export const ChangeMenuCollapsed = (payload) => ({
  type: MENU_COLLAPSED_CHANGED,
  payload,
});

export const UpdateCurrentRoot = (payload) => ({
  type: CURRENT_ROUTE,
  payload,
});

export const UpdateVendorList = (payload) => ({
  type: UPDATE_VENDOR_LIST,
  payload: payload,
});
export const UpdateSupplierList = (payload) => ({
  type: UPDATE_SUPPLIER_LIST,
  payload: payload,
});
export const UpdateVendorTopupList = (payload) => ({
  type: UPDATE_VENDOR_TOPUP_LIST,
  payload: payload,
});
export const addRemoveBalance = (balanceList, currency, amount) => {
  let tmpList = [...balanceList];
  let selBalance = tmpList.filter(
    (x) => x["Currency"]["iso"] == currency["iso"]
  );
  if (selBalance.length == 0) {
    tmpList = [
      ...tmpList,
      {
        Currency: currency,
        amount: amount,
      },
    ];
  } else {
    selBalance = selBalance[0];
    selBalance["amount"] =
      parseFloat(selBalance["amount"]) + parseFloat(amount);
  }
  return tmpList;
};
export const UpdateSupplierTopupList = (payload) => ({
  type: UPDATE_SUPPLIER_TOPUP_LIST,
  payload: payload,
});
export const UpdateVendorWithdrawList = (payload) => ({
  type: UPDATE_VENDOR_WITHDRAW_LIST,
  payload: payload,
});
