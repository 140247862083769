import React, { useState } from "react";
import { Modal, Input, message, Row, Col, Select } from "antd";
import { confirmable } from "react-confirm";
import "./PurchaseInfoDlg.css";
const { Option } = Select;
const PurchaseInfoDlg = (props) => {
  const { show, proceed } = props;
  const { name, MainUser, balance, title, isPurchase, Balance } = props;
  const [amount, setAmount] = useState(500);

  const [selCurrency, setSelCurrency] = useState(Balance[0]["Currency"]["iso"]);
  const currentBalance =
    isPurchase == true
      ? parseFloat(
          Balance.find((x) => x["Currency"]["iso"] == selCurrency)["amount"]
        ) + parseFloat(amount)
      : parseFloat(
          Balance.find((x) => x["Currency"]["iso"] == selCurrency)["amount"]
        ) - parseFloat(amount);
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      className="radial-modal"
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={true}
      title={title}
      onOk={() => {
        try {
          if (parseFloat(amount) <= 0) {
            message.error("Amount should be greater than R0.");
            return;
          }
          if (currentBalance < 0) {
            message.error("Specified amount is invalid.");
            return;
          }
        } catch (e) {
          message.error("Amount is not valid.");
          return;
        }
        proceed({ currency: selCurrency, amount: parseFloat(amount) });
      }}
    >
      <div>Vendor: {name}</div>
      <div>User Name: {MainUser}</div>
      <div
        style={{ marginTop: 10, color: "var(--redColor)", marginBottom: 10 }}
      >
        *Please specify the amount carefully.
      </div>
      <Row align={"middle"} gutter={16}>
        <Col>
          <div>Currency</div>
          <Select
            className={"purchase-amount-input"}
            value={selCurrency}
            style={{ width: 150 }}
            bordered={false}
            onChange={(e) => setSelCurrency(e)}
          >
            {Balance.map((balance, index) => {
              return (
                <Option
                  value={balance["Currency"]["iso"]}
                  key={balance["Currency"]["iso"]}
                >
                  {balance["Currency"]["simple"]}({balance["Currency"]["iso"]})
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col>
          <div>Amount</div>
          <Input
            className={"purchase-amount-input"}
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
        </Col>
      </Row>
      <div style={{ textAlign: "center", marginTop: 10 }}>
        The balance will be{" "}
        <span
          style={{
            fontFamily: "mediumFont",
            color:
              currentBalance >= 0 ? "var(--blackColor)" : "var(--redColor)",
          }}
        >
          {`${currentBalance >= 0 ? "" : "-"}` +
            Balance.find((x) => x["Currency"]["iso"] == selCurrency)[
              "Currency"
            ]["simple"] +
            Math.abs(currentBalance)}
        </span>
      </div>
    </Modal>
  );
};

export default confirmable(PurchaseInfoDlg);
