import { axiosDelete, axiosGet, axiosPost } from "./ajaxServices";
import {
  urlAcceptVendorTopup,
  urlAcceptVendorWithdraw,
  urlGetVendorBankDetail,
  urlGetVendorTopup,
  urlGetVendorWithdrawList,
  urlRejectVendorWithdraw,
} from "./CONSTANTS";

export const apiGetVendorBankDetail = (withdraw_id) => {
  const url = urlGetVendorBankDetail;
  return new Promise((resolve, reject) => {
    axiosGet(url, { withdraw_id: withdraw_id }, "apiGetVendorBankDetail")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiGetVendorWithdrawList = () => {
  const url = urlGetVendorWithdrawList;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, "apiGetVendorWithdrawList")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiAcceptVendorWithdraw = (withdraw_id) => {
  const url = urlAcceptVendorWithdraw;
  let param = {
    withdraw_id: withdraw_id,
  };

  return new Promise((resolve, reject) => {
    axiosPost(url, param, "apiAcceptVendorWithdraw")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiRejectVendorWithdraw = (withdraw_id, description = "") => {
  const url = urlRejectVendorWithdraw;
  let param = {
    withdraw_id: withdraw_id,
    description: description,
  };

  return new Promise((resolve, reject) => {
    axiosDelete(url, param, "apiRejectVendorWithdraw")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
