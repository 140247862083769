import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateUser } from "redux/actions/userActions";
import { apiActivateUser, apiLogin, apiRegister } from "services/userServices";

export function useProvideAuth() {
  const userData = useSelector((x) => x.userDataStore);
  const dispatch = useDispatch();
  // signin method: It can either return a promise or execute a callback function.
  // You can prefer to keep this in userServices.js
  const signin = (userDetail) => {
    return new Promise((resolve, reject) => {
      apiLogin(userDetail)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  
  const signout = () => {
    return new Promise((resolve, reject) => {
      try {
        // do API endpoint axios call here and return the promise.
        // setUser(null);
        dispatch(updateUser({ token: undefined }));
        resolve(true);
      } catch (error) {
        console.error("signout error!==", error);
        reject("signout error!");
      }
    });
  };

  return {
    userData,
    signin,
    signout,
  };
}
