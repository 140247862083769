import React, { useRef } from "react";
import { Button, Col, Divider, Input, Row } from "antd";
import AuthDialogWidget from "components/AuthDialogWidget/AuthDialogWidget";
import AuthLogoWidget from "components/AuthLogoWidget/AuthLogoWidget";
import NormalButton from "components/ButtonWidgets/NormalButton/NormalButton";
import TextButton from "components/ButtonWidgets/TextButton/TextButton";
import InputEmailOrPhone from "components/InputWidgets/InputEmailOrPhone/InputEmailOrPhone";
import InputPassword from "components/InputWidgets/InputPassword/InputPassword";
import { useState } from "react";
import { REGISTER_WITH } from "config/CONSTANTS";
import { validateEmail } from "services/commonService";
import { ROUTE_FORGOT_PASSWORD } from "navigation/CONSTANTS";
import { useHistory, withRouter } from "react-router";

function InputPage(props) {
  const { userDetail, setUserDetail, isBusy } = props;
  const { onClickLogin } = props;
  const [wrongList, setWrongList] = useState([]);

  const validateFields = () => {
    const { email, phone, password, register_with } = userDetail;
    let tmpWrongList = [];
    if (register_with === REGISTER_WITH.phone) {
      if (phone === undefined || phone === "") {
        tmpWrongList.push("phone");
      }
    } else {
      if (validateEmail(email) === false) {
        tmpWrongList.push("email");
      }
    }
    if (password === undefined || password === "") {
      tmpWrongList.push("password");
    }
    setWrongList(tmpWrongList);

    return tmpWrongList.length === 0;
  };

  const onClickNext = () => {
    if (validateFields() === false) {
      return;
    }
    onClickLogin();
  };
  return (
    <div style={{ color: "var(--blackColor)", textAlign: "center" }}>
      <AuthDialogWidget title={"Login"}>
        <div style={{ marginTop: 20 }}>
          <InputEmailOrPhone
            userDetail={userDetail}
            setUserDetail={setUserDetail}
            wrongList={wrongList}
            setWrongList={setWrongList}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <InputPassword
            userDetail={userDetail}
            setUserDetail={setUserDetail}
            wrongList={wrongList}
            onClickEnter={onClickNext}
            setWrongList={setWrongList}
          />
        </div>
        <div style={{ marginTop: 20, padding: "30px 0px" }}>
          <NormalButton
            style={{ backgroundColor: "var(--blueColor)" }}
            onClick={onClickLogin}
            isBusy={isBusy}
          >
            Login
          </NormalButton>
        </div>

        <div>
          <Divider />
        </div>
      </AuthDialogWidget>
    </div>
  );
}

export default InputPage;
