import { message, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DATE_LIST } from "services/commonService";
import { apiGetAddressList, apiGetUsersList } from "services/usersServices";
import InputLocationWidget from "./InputLocationWidget/InputLocationWidget";
import "./UserPage.css";
import UserSearchDetailWidget from "./UserSearchDetailWidget/UserSearchDetailWidget";
import UsersListWidget from "./UsersListWidget/UsersListWidget";

const UserPage = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [username, setUsername] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [fromDate, setFromDate] = useState(DATE_LIST[0]);
  const readUsersList = (
    _offset = 0,
    _page_size = 10,
    _user_name = undefined,
    _placeId = undefined,
    _fromDate = undefined
  ) => {
    if (_user_name == undefined) {
      _user_name = username;
    } else {
      setUsername(_user_name);
    }
    if (_placeId == undefined) {
      _placeId = placeId;
    } else {
      setPlaceId(_placeId);
    }
    if (_fromDate == undefined) {
      _fromDate = fromDate;
    } else {
      setFromDate(_fromDate);
    }

    setOffset(_offset);
    setPageSize(_page_size);
    setIsBusy(true);

    apiGetUsersList(
      _offset,
      _page_size,
      _user_name,
      _placeId,
      _fromDate["fromDate"]
    ).then((res2) => {
      const { totalCount, data } = res2;
      setUsersList(data);
      setTotalCount(totalCount);
      setIsBusy(false);
    });
  };

  return (
    <div>
      <div>
        <UserSearchDetailWidget
          readUsersList={readUsersList}
          prevFromDate={fromDate}
        />
      </div>
      <div style={{ marginTop: 30 }}>
        <UsersListWidget
          totalCount={totalCount}
          usersList={usersList}
          offset={offset}
          pageSize={pageSize}
          isBusy={isBusy}
          readUsersList={readUsersList}
        />
      </div>
    </div>
  );
};

export default UserPage;
