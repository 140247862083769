import { SearchOutlined } from "@ant-design/icons";
import { Input, message, Row, Col, Table, Spin } from "antd";
import { showTopupAcceptDlg } from "components/TopupAcceptDlg/showTopupAcceptDlg";
import { showTopupRejectDlg } from "components/TopupRejectDlg/showTopupRejectDlg";
import { showWithdrawAcceptDlg } from "components/WithdrawAcceptDlg/showWithdrawAcceptDlg";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { UpdateVendorWithdrawList } from "redux/actions/appActions";

import {
  apiAcceptVendorWithdraw,
  apiRejectVendorWithdraw,
} from "services/vendorWithdrawService";

import "./WithdrawTableView.css";
const WithdrawTableView = (props) => {
  const { dataList, isSupplier } = props;
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const [selKeys, setSelKeys] = useState([]);

  const columns = [
    { title: "No", dataIndex: "order", width: 50 },
    {
      title: "Name",
      width: 150,
      dataIndex: "name",
      sorter: (a, b) => a.name > b.name,
    },
    {
      title: "Logo",
      dataIndex: "logo",
      width: 100,
      render: (logo) => {
        if (logo == undefined || logo == "") {
          return <div />;
        }
        return <img style={{ width: "100%" }} src={logo} />;
      },
    },

    {
      title: "MainUser",
      width: 100,
      dataIndex: "MainUser",
      sorter: (a, b) => a.MainUser > b.MainUser,
    },

    {
      title: "Withdrawal Amount",
      dataIndex: "price",
      width: 100,
      sorter: (a, b) => a.price > b.price,
      render: (info, data) => {
        return (
          <div
            key={`balance-item-${data["Currency"]["iso"]}`}
            style={{
              color:
                data["price"] >= 0 ? "var(--blueColor)" : "var(--redColor)",
            }}
          >
            {data["Currency"]["simple"]}
            {data["price"]}
          </div>
        );
      },
    },

    {
      title: "Description",
      width: 200,
      dataIndex: "description",
      sorter: (a, b) => a.description > b.description,
    },

    {
      title: "Balance",
      width: 70,
      sorter: (a, b) => a.balance > b.balance,
      render: (balance, data) => {
        const balanceList = data["Balance"].map((balance) => {
          return (
            <div
              key={`balance-item-${balance["Currency"]["iso"]}`}
              style={{
                color:
                  balance["amount"] >= 0
                    ? "var(--blueColor)"
                    : "var(--redColor)",
              }}
            >
              {balance["Currency"]["simple"]}
              {balance["amount"]}
            </div>
          );
        });
        return balanceList;
      },
    },
    {
      title: "Created",
      dataIndex: "created",
      width: 110,
      sorter: (a, b) => a.created > b.created,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: 120,
      fixed: "right",
      render: (info) => {
        return (
          <div>
            <div style={{ textAlign: "center" }}>
              <span
                className="custom-button withdraw-table-view-button"
                style={{ color: "var(--blueColor)" }}
                onClick={() => {
                  onClickAcceptWithdraw(info);
                }}
              >
                Accept
              </span>
            </div>
            <div style={{ textAlign: "center" }}>
              <span
                className="custom-button withdraw-table-view-button"
                style={{ color: "var(--redColor)" }}
                onClick={() => {
                  onClickRejectWithdraw(info);
                }}
              >
                Reject
              </span>
            </div>
          </div>
        );
      },
    },
  ];
  const onClickAcceptWithdraw = async (info) => {
    const isAccepted = await showWithdrawAcceptDlg({ info: info });
    if (isAccepted != true) return;

    if (isSupplier) {
      //   updateSupplierList(info.withdraw_id, info.id, acceptAmount);
    } else {
      removeItemFromList(info.withdraw_id);
    }
  };

  const removeItemFromList = (withdraw_id) => {
    let data = dataList.filter((x) => x.id != withdraw_id);

    dispatch(UpdateVendorWithdrawList(data));
  };

  const onClickRejectWithdraw = async (info) => {
    const rejectInfo = await showTopupRejectDlg({ info: info });
    if (rejectInfo == null) return;

    if (isSupplier) {
      //   apiRejectSupplierTopUp(info.withdraw_id, rejectInfo);
      //   dispatch(UpdateSupplierTopupList(updatedList));
    } else {
      apiRejectVendorWithdraw(info.withdraw_id, rejectInfo);
    }
    removeItemFromList(info.withdraw_id);
  };

  return (
    <div>
      <Row justify={"space-between"}>
        <Col xs={24} md={12}>
          <Input
            prefix={<SearchOutlined />}
            className="withdraw-data-search-input"
            placeholder={"Search by keyword"}
            type={"search"}
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Table
        className="withdraw-list-view-table"
        columns={columns}
        dataSource={dataList
          .filter((x) => {
            const Target = isSupplier ? x.Supplier : x.Vendor;
            return (
              (Target.name != undefined &&
                Target.name.toLowerCase().includes(keyword.toLowerCase())) ||
              (Target.MainUser != undefined &&
                Target.MainUser.toLowerCase().includes(
                  keyword.toLowerCase()
                )) ||
              (Target.Subscription != undefined &&
                Target.Subscription.toLowerCase().includes(
                  keyword.toLowerCase()
                )) ||
              (Target.email != undefined &&
                Target.email.toLowerCase().includes(keyword.toLowerCase()))
            );
          })
          .map((x, index) => {
            const Target = isSupplier ? x.Supplier : x.Vendor;
            return {
              key: x.id,
              ...Target,
              order: index + 1,
              price: x.price.toFixed(2),
              Currency: x.Currency,
              description: x.description,
              created: x.created.substring(0, 10),
              withdraw_id: x.id,
            };
          })}
        scroll={{ x: 1000 }}
      />
    </div>
  );
};

export default WithdrawTableView;
