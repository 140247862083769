import { Spin } from "antd";
import TopupTableView from "components/TopupTableView/TopupTableView";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateVendorTopupList } from "redux/actions/appActions";
import { apiGetVendorTopUps } from "services/vendorTopupService";

const VendorTopupPage = (props) => {
  const dispatch = useDispatch();
  const appDataStore = useSelector((x) => x.appDataStore);
  useEffect(() => {
    apiGetVendorTopUps()
      .then((res) => {
        dispatch(UpdateVendorTopupList(res.data));
      })
      .catch((err) => {
      });
  }, []);
  const { vendorTopupList } = appDataStore;
  if (vendorTopupList === undefined) {
    return (
      <Spin>
        <div style={{ height: 300, textAlign: "center", paddingTop: 150 }}>
          Loading Data
        </div>
      </Spin>
    );
  }
  return (
    <div>
      <TopupTableView dataList={vendorTopupList} isSupplier={false} />
    </div>
  );
};

export default VendorTopupPage;
