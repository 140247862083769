import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSupplierList } from "redux/actions/appActions";
import { getSupplierList } from "services/homeService";
import "./SupplierPage.css";
import TableViewer from "../../components/TableViewer/TableViewer";
const SupplierPage = () => {
  const appDataStore = useSelector((x) => x.appDataStore);
  const dispatch = useDispatch();
  useEffect(() => {
    getSupplierList().then((res) => {
      dispatch(UpdateSupplierList(res.data));
    });
  }, []);
  const { supplierList } = appDataStore;
  if (supplierList === undefined) {
    return (
      <Spin>
        <div style={{ height: 300, textAlign: "center", paddingTop: 150 }}>
          Loading Data
        </div>
      </Spin>
    );
  }
  return (
    <div>
      <TableViewer dataList={supplierList} isSupplier={true} />
    </div>
  );
};

export default SupplierPage;
