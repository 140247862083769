import { axiosDelete, axiosGet, axiosPost } from "./ajaxServices";
import { urlAcceptSupplierTopup, urlGetSupplierTopup } from "./CONSTANTS";

export const apiGetSupplierTopUps = () => {
  const url = urlGetSupplierTopup;
  return new Promise((resolve, reject) => {
    axiosGet(url, {}, "apiGetSupplierTopUps ")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiAcceptSupplierTopUp = (topup_id, updated_price, currency) => {
  const url = urlAcceptSupplierTopup;
  let param = {
    topup_id: topup_id,
    updated_price: updated_price,
  };

  return axiosPost(url, param, "apiAcceptSupplierTopUp ");
};
export const apiRejectSupplierTopUp = (topup_id, description = "") => {
  const url = urlAcceptSupplierTopup;
  let param = {
    topup_id: topup_id,
    description: description,
  };

  return new Promise((resolve, reject) => {
    axiosDelete(url, param, "apiRejectSupplierTopUp ")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
