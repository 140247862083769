import { googleKey } from "config/CONSTANTS";

// export const BASE_URL = "http://192.168.0.57:8000";
export const BASE_URL = "https://v5.admin.seemesave.com";
// export const BASE_URL = "https://live.v5.admin.seemesave.com";

export const urlUserLogin = BASE_URL + "/master_api/auth/";

export const urlGetVendorList = BASE_URL + "/master_api/vendor/";
export const urlVendorEmailSend = BASE_URL + "/master_api/vendor/email/";
export const urlGetPaymentListOfVendor = BASE_URL + "/vendor_api/v3/payment/";
export const urlVendorPurchase = BASE_URL + "/vendor_api/v3/payment/purchase/";
export const urlVendorWithdraw = BASE_URL + "/vendor_api/v3/payment/withdraw/";

export const urlGetVendorTopup = BASE_URL + "/master_api/vendor/top-up/";
export const urlAcceptVendorTopup =
  BASE_URL + "/master_api/vendor/top-up/accept/";
export const urlRejectVendorTopup =
  BASE_URL + "/master_api/vendor/top-up/reject/";

export const urlGetSupplierList = BASE_URL + "/master_api/supplier/";
export const urlSupplierEmailSend = BASE_URL + "/master_api/supplier/email/";
export const urlGetPaymentListOfSupplier =
  BASE_URL + "/supplier_api/v3/payment/";
export const urlSupplierPurchase =
  BASE_URL + "/supplier_api/v3/payment/purchase/";
export const urlSupplierWithdraw =
  BASE_URL + "/supplier_api/v3/payment/withdraw/";

export const urlGetSupplierTopup = BASE_URL + "/master_api/supplier/top-up/";
export const urlAcceptSupplierTopup =
  BASE_URL + "/master_api/supplier/top-up/accept/";
export const urlRejectSupplierTopup =
  BASE_URL + "/master_api/supplier/top-up/reject/";

export const urlGetVendorWithdrawList =
  BASE_URL + "/master_api/vendor/withdraw/";
export const urlAcceptVendorWithdraw =
  BASE_URL + "/master_api/vendor/withdraw/accept/";
export const urlRejectVendorWithdraw =
  BASE_URL + "/master_api/vendor/withdraw/reject/";
export const urlGetVendorBankDetail =
  BASE_URL + "/master_api/vendor/withdraw/bank-detail/";

export const urlGetAddressList = BASE_URL + "/master_api/users/address-list/";
export const urlGetUsersList = BASE_URL + "/master_api/users/users-list/";

export const urlGoogleAutoComplete =
  BASE_URL + "/api/v3/common/google-auto-complete/";
