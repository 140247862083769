import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Layout, Menu, Divider } from "antd";
import { MENU_ITEMS, ROUTE_AGENT } from "navigation/CONSTANTS";
import collapseIcon from "../../assets/icons/ic_collapse.svg";
import "./SidebarWidget.css";

import { IconWidget } from "components/IconWidget/IconWidget";

import {
  ChangeMenuCollapsed,
  UpdateCurrentRoot,
} from "redux/actions/appActions";

const SidebarWidget = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const appDataStore = useSelector((x) => x.appDataStore);
  const { collapsed, width } = appDataStore;
  const currentRoute = history.location.pathname;
  const menuUI = MENU_ITEMS.map((info, index) => {
    return (
      <Menu.Item
        className="c-menu-item"
        key={info.route}
        icon={info.icon}
        onClick={() => {
          history.push(info.route);
        }}
      >
        {info.title}
      </Menu.Item>
    );
  });


  const onToggleCollapsed = () => {
    dispatch(ChangeMenuCollapsed({ collapsed: !collapsed }));
  };
  return (
    <div>
      <div className="logo">
        {!collapsed ? (
          <img
            src={"/assets/images/logo.svg"}
            alt="SeemeSave"
            className="logo-img"
          />
        ) : (
          <img
            src={"/small-logo.svg"}
            alt="SeemeSave"
            className="favicon-img"
          />
        )}
        {width > 1024 ? (
          <div
            className="side-collapse"
            onClick={() => {
              onToggleCollapsed();
            }}
          >
            <IconWidget Icon={collapseIcon} />
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Divider />
      <div className="layout-store-name"></div>
      <Menu
        mode="inline"
        // defaultSelectedKeys={[currentRoute]}
        selectedKeys={[currentRoute]}
        className="c-menu"
        onSelect={(v) => {
          dispatch(UpdateCurrentRoot({ currentRoute: v.key }));
        }}
      >
        {menuUI}
      </Menu>
      <Layout>{props.children}</Layout>
    </div>
  );
};

export default SidebarWidget;
