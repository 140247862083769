// You can use CONSTANTS.js file for below definitions of constants and import here.
export const UPDATE_USER = "UPDATE_USER";
export const SET_FROM_URL = "set-from-url";

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: { ...user },
});
export const setFromUrl = (url) => ({
  type: SET_FROM_URL,
  payload: url,
});
