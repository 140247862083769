import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Input, message } from "antd";

import "./TopupRejectDlg.css";
import { confirmable } from "react-confirm";
const TopupRejectDlg = (props) => {
  const { show, proceed } = props;
  const { info } = props;
  const [reason, setReason] = useState("");
  const { name, MainUser, price, Currency } = info;

  const priceName =
    info.withdraw_id == undefined ? "Requested Price" : "Withdrawal Price";
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      className="radial-modal"
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={true}
      title={"Reject Request."}
      onOk={() => {
        proceed(reason);
      }}
    >
      <div>Vendor: {name}</div>
      <div>User Name: {MainUser}</div>
      <div style={{ marginTop: 10 }}>
        {priceName}: {Currency["simple"]}
        {price}
      </div>
      <div
        style={{ marginTop: 10, color: "var(--redColor)", marginBottom: 10 }}
      >
        *Please write the reason
      </div>
      <Input.TextArea
        className={"topup-reject-reason"}
        value={reason}
        placeholder={"Reject reason."}
        rows={5}
        onChange={(e) => {
          setReason(e.target.value);
        }}
      />
    </Modal>
  );
};

export default confirmable(TopupRejectDlg);
