// @flow strict

import { Divider, Spin, Row, Col } from "antd";
import InfoPaneWidget from "components/InfoPaneWidget/InfoPaneWidget";
import { ROUTE_SUPPLIER, ROUTE_VENDOR } from "navigation/CONSTANTS";
import React from "react";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UpdateSupplierList, UpdateVendorList } from "redux/actions/appActions";
import { getSupplierList, getVendorList } from "services/homeService";
import "./HomePage.css";
const OverViewInfo = (props) => {
  const history = useHistory();
  const {
    title,
    totalCount,
    activeCount,
    totalFollowers,
    totalBalance,
    route,
  } = props;
  return (
    <div>
      <div className="overview-info-title">{title}</div>
      <Divider style={{ margin: 0 }} />
      <div style={{ marginLeft: 30 }}>
        <div style={{ marginTop: 10 }}>
          <span className="overview-info-item-title">Total Count: </span>
          <span className="overview-info-item-value">{totalCount}</span>
        </div>
        <div style={{ marginTop: 10 }}>
          <span className="overview-info-item-title">Active Count: </span>
          <span className="overview-info-item-value">{activeCount}</span>
        </div>
        <div style={{ marginTop: 10 }}>
          <span className="overview-info-item-title">Total Followers: </span>
          <span className="overview-info-item-value">{totalFollowers}</span>
        </div>
        <div style={{ marginTop: 10 }}>
          <span className="overview-info-item-title">Total Balance: </span>
          <span className="overview-info-item-value">
            {`${totalBalance >= 0 ? "" : "-"}`}R{Math.abs(totalBalance)}
          </span>
        </div>
      </div>
      <div style={{ marginTop: 50, textAlign: "center", marginBottom: 50 }}>
        <span
          className={"overview-info-button custom-button"}
          onClick={() => {
            history.push(route);
          }}
        >
          See Detail
        </span>
      </div>
    </div>
  );
};
function HomePage(props) {
  const appDataStore = useSelector((x) => x.appDataStore);
  const dispatch = useDispatch();
  useEffect(() => {
    getVendorList().then((res) => {
      dispatch(UpdateVendorList(res.data));
    });
    getSupplierList().then((res) => {
      dispatch(UpdateSupplierList(res.data));
    });
  }, []);

  const { vendorList, supplierList } = appDataStore;
  if (vendorList === undefined || supplierList === undefined) {
    return (
      <Spin>
        <div style={{ height: 300, textAlign: "center", paddingTop: 150 }}>
          Loading Data
        </div>
      </Spin>
    );
  }
  // return this.reduce((a, b) => a + (b[key] || 0), 0);
  return (
    <div>
      
      <Row gutter={[32, 32]} justify={"space-around"}>
        <Col xs={24} md={12}>
          <InfoPaneWidget>
            <OverViewInfo
              title={"Supplier Information"}
              totalCount={supplierList.length}
              activeCount={
                supplierList.filter(
                  (x) => x.is_closed == false && x.MainUser != ""
                ).length
              }
              totalFollowers={supplierList.reduce(
                (a, b) => a + b.FollowerCount,
                0
              )}
              totalBalance={supplierList.reduce((a, b) => a + b.balance, 0)}
              route={ROUTE_SUPPLIER}
            />
          </InfoPaneWidget>
        </Col>
        <Col xs={24} md={12}>
          <InfoPaneWidget>
            <OverViewInfo
              title={"Vendor Information"}
              totalCount={vendorList.length}
              activeCount={
                vendorList.filter(
                  (x) => x.is_closed == false && x.MainUser != ""
                ).length
              }
              totalFollowers={vendorList.reduce(
                (a, b) => a + b.FollowerCount,
                0
              )}
              totalBalance={vendorList.reduce((a, b) => a + b.balance, 0)}
              route={ROUTE_VENDOR}
            />
          </InfoPaneWidget>
        </Col>
      </Row>

      <div style={{ marginTop: 50 }} />
    </div>
  );
}

export default HomePage;
